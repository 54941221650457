:root{
    font-size: 13px;
}
  
$page_headline_size: 3.4em;
$modal_headline_size: 2.3em;
$placeholder_size: 0.9em;
$body_size: 1em;
  
%page_headline {
    font-size: $page_headline_size;
    font-weight: bold;
    font-style: normal;
}
  
%modal_headline {
    font-size: $modal_headline_size;
    font-weight: bold;
    font-style: normal;
}
  
%strong_font_style {
    font-size: inherit;
    font-weight: bold;
    font-style: normal;
}
%placeholder_font_style {
  font-size: $placeholder_size;
  font-weight: normal;
  font-style: normal;
  opacity: 0.8;
}
%quote_font_style {
  font-size: inherit;
  font-weight: 400;
  font-style: italic;
}
%button_font_style {
  font-size: inherit;
  font-weight: 500;
}