.hs-footer{
    position: relative;
    width: 100%;
    height: 100vh;
    background: $color;
    color: $background;
    padding: 30px;
    &__container{
        position: relative;
        width: 100%;
        max-width: 1280px;
        height: 100%;
        margin: auto;
        @extend %flex-vertical-center;
        @include media('<=580px'){
            flex-direction: column;
            justify-content: space-between;
        }
    }
    &__bottom{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        padding: 30px 0;
        border-top: 0.5px solid rgba($color: #ffffff, $alpha: .2);
        @include media('<=580px'){
            position: relative;
            flex-direction: column;
            row-gap: 20px;
            justify-content: center;
            text-align: center;
        }
    }
    &__work{
        @include media('<=580px'){
            height: 90vh;
            display: flex;
            align-items: center;
        }
        span{
            font-size: 22px;
        }
        h1{
            font-size: 5vw;
            font-family: $font-title;
            font-weight: 500;
            text-transform: uppercase;
            a{
                position: relative;
                width: auto;
                height: auto;
                &:hover{
                    color: inherit;
                    &::after{
                        transform: scaleX(1);
                    }
                }
                &::after{
                    @include pseudo-el;
                    bottom: 10px;
                    left: 0;
                    width: 55%;
                    height: 1px;
                    background: currentColor;
                    transform-origin: left;
                    transition: 0.5s ease-out;
                    transform: scaleX(0);
                }
            }
            @include media('<=580px'){
                font-size: 26px;
                text-align: center;
            }
        }
    }
    .hs-copyright__work{
        a{
            opacity: 0.6;
            transition: 0.5s ease;
            text-decoration: underline;
            &:hover{
                color: inherit;
                opacity: 1;
            }
        }
    }
}