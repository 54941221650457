%relative-all {
    position: relative;
    width: 100%;
    height: 100%;
}

%flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
%flex-vertical-center {
    display: flex;
    align-items: center;
}
%image-center {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
}

%image-height-auto{
    width: 100% !important;
    height: auto;
}
%image-width-auto{
    height: 100% !important;
    width: auto;
    object-fit: cover;
    object-position: center;
    margin: auto;
}
%list-style-none{
    list-style: none;
}
%avatar-box {
    border-radius: 50%;
    overflow: hidden;
    background: var(--background-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}
