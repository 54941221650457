.hs-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    color: #fff;
    mix-blend-mode: difference;
    z-index: 10;
}
.hs-header-container{
    position: relative;
    width: 100%;
    padding: 1em 2em;
    display: flex;
    justify-content: space-between;
    color: currentColor;
    @include media('<=990px'){
        padding: 1em;
    }
}
.hs-header-logo{
    position: relative;
    svg{
        width: 2.5em;
        height: auto;
        path {
            fill: currentColor;
        }
    }
}
.hs-header-burger{
    position: relative;
    color: currentColor;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: .5em;
    &.is--active{
        .hs-header-burger-line{
            &:nth-child(2){
                transform: translateY(-50%) scaleX(0);
            }
            &:nth-child(1){
                transform: rotate(45deg) translateX(25%);
            }
            &:nth-child(3){
                transform: rotate(-45deg) translateX(25%);
            }
        }
    }
}
.hs-header-burger-lines{
    position: relative;
    width: 2.5em;
    height: .8em;
    transition: .5s ease;
}
.hs-header-burger-line{
    position: absolute;
    width: 100%;
    height: .08em;
    background-color: currentColor;
    transition: .5s ease;

    &:nth-child(1){
        top: 0;
        left: 0;
    }
    &:nth-child(3){
        bottom: 0;
        left: 0;
    }
    &:nth-child(2){
        top: 50%;
        left: 0;
        transform: translateY(-50%) scaleX(1);
    }
}

.hs-navigation{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100svh;
    background: $color;
    z-index: 9;
    color: $background-secondary;
    padding: 3em 2em;
    padding-bottom: 2em;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    @include media('<=990px'){
        padding-top: 25svh;
    }
}

.hs-navigation-container{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
}
.hs-navigation-list{
    position: relative;
    width: 100%;
    padding: 2em 0;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}
.hs-navigation-item{
    position: relative;
    width: 100%;
    overflow: hidden;
    // clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    a{
        display: block;
        font-size: 6svh;
        font-family: $font-body;
        text-transform: uppercase;
        position: relative;
        width: 100%;
        opacity: .4;
        transition: .5s ease-in-out;
        // line-height: 0;
       
        @include media('<=990px'){
            font-size: 4svh;
        }
        &:hover{
            opacity: .6;
            // font-weight: bold;
        }
        &.active{
            opacity: 1;
            // font-weight: bold;
            // text-transform: uppercase;
            // font-family: $font-title;
            .hs-arrow{
                display: block;
                transform: rotate(-35deg);
            }
        }
        .hs-arrow{
            position: absolute;
            top: .6em;
            right: 0;
            width: 2em;
            height: 1em;
            display: none;

            &__container{
                position: relative;
                width: 100%;
                height: 100%;
                &::after{
                    @include pseudo-el;
                    width: 100%;
                    height: 0.01em;
                    background-color: currentColor;
                    top: 50%;
                    left: 0;
                    transform: translateY(50%);
                }
                &::before{
                    @include pseudo-el;
                    width: .8em;
                    height: .8em;
                    border: .01em solid currentColor;
                    border-top: none;
                    border-left: none;
                    top: .1em;
                    right: 0;
                    transform: rotate(-45deg);
                }
                
            }
            
        }
    }
}

.hs-navigation-footer{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 2svh;
    border-top: .002em solid rgba($color: $background-secondary, $alpha: .25);
    font-size: .95em;
    @include media('<=990px'){
        display: none;
    }
}

.hs-navigation-footer-description{
    position: relative;
    width: 50%;
    h1{
        font-size: 6em;
        font-family: $font-title;
        display: flex;
        position: relative;
        width: 100%;
    }
}
.hs-navigation-footer-links{
    position: relative;
    width: 100%;
    max-width: 85em;
    display: flex;
}
.hs-navigation-footer-socials,
.hs-navigation-footer-contacts{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    // text-align: right;
   
    h2{
        // text-align: right;
        font-weight: 400;
        display: block;
        width: 100%;
        
    }
}
.hs-navigation-footer-socials-list,
.hs-navigation-footer-contacts-list{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .5em;
    span{
        opacity: .6;
    }
    strong{
        font-weight: bolder;
    }
}
.hs-navigation-footer-link{
    display: block;
    width: 100%;
    opacity: .5;
    transition: .3s ease-in-out;
    &:hover{
        opacity: 1;
    }
}

.hs-main{
    position: relative;
    width: 100%;
}