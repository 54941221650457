.hs-home{
    position: relative;
    width: 100%;
}
.hs-home-banner{
    position: relative;
    width: 100%;
    height: 100svh;
    overflow: hidden;
    padding: 0 2em;
    @include media('<=520px'){
        padding: 0 1em;
    }
}
.hs-home-banner-container{
    position: relative;
    width: 100%;
    height: 35svh;
    @extend %flex-center;
    flex-direction: column;
    row-gap: 1em;
    text-align: center;
    strong{
        position: relative;
        font-size: 1em;
        font-weight: bolder;
        @include media('<=290px'){
            font-size: 7svw;
        }
    }
    h1{
        font-size: 12svh;
        text-transform: uppercase;
        font-family: $font-body;
        display: flex;
        overflow-y: hidden;
        font-weight: bold;
        padding: 0 1em;
        
        @include media('<=990px'){
            font-size: 3em;
        }
        @include media('<=520px'){
            font-size: 1.5em;
        }
        @include media('<=290px'){
            font-size: 10svw;
        }
        span{
            display: block;
        }
    }
}
.hs-home-banner-images{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
}
.hs-home-banner-image{
    position: relative;
    width: 100%;
    max-width: 15em;
    height: 40vh;
    overflow: hidden;
    .hs-blanc, .hs-noir{
        transform-origin: top
    }
    .hs-home-banner-image-contain{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    img{
        @extend %image-center;
    }
    &.mid{
        position: relative;
        max-width: 35em;
        width: 35em;
        height: 65svh;
        padding: 0 .25em;
        
    }
}
.hs-home-introduce{
    position: relative;
    width: 100%;
    height: 100svh;
    padding: 0 2em;
    @extend %flex-center;
    &__container{
        position: relative;
        width: 100%;
        max-width: 48em;
        text-align: center;
        font-size: 2.3em;

        @include media('<=990px'){
            font-size: 1.3em;
        }
        p{
            opacity: 0.1;
            strong{
                font-weight: bold;
            }
        }
    }
}

.hs-home-about{
    position: relative;
    width: 100%;
    height: 100svh;
    padding: 0 2em;
    overflow-x: hidden;
}
.hs-home-about-image{
    position: absolute;
    top: 50%;
    left: 2em;
    width: calc(100% - 4em);
    max-width: calc(100% - 4em);
    height: 100svh;
    overflow: hidden;
    transform: translateY(-50%);
    img{
        @extend %image-center;
        transform: scale(1.2);
    }
}
.hs-home-about-container{
    position: relative;
    width: 70%;
    max-width: 50em;
    margin-left: auto;
    height: 100svh;
    opacity: 0;
    @extend %flex-center;
    transform: translateX(100%);
    background: $background;
}
.hs-home-about-text{
    position: relative;
    width: 100%;
    max-width: 48em;
    padding: 0 1em;
    h1{
        font-size: 1.4em;
        @include media('<=990px'){
            font-size: .9em;
        }
        p{
            opacity: 0.1;
            transform: translateX(100%);
            strong{
                font-weight: bold;
            }
        }
    }
}
.hs-home-services{
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: $color;
    color: #ffffff;
    // background-color: #2e0531;
    transition: 0.3s ease-out;
    @extend %flex-center;
    padding: 4svh 2em;
    @include media('<=800px'){
        padding: 2svh 1em;
    }
    &__container{
        position: relative;
        width: 100%;
        max-width: 80em;
    }
    &__head{
        position: relative;
        margin-bottom: 2.1em;
        mix-blend-mode: difference;
        h2{
            display: flex;
            column-gap: 1emx;
            font-size: 1.2em;
            margin-bottom: .3em;
            span{
                font-weight: 200;
            }
            strong{
                font-weight: 500;
                text-transform: uppercase;
            }
        }
        h1{
            width: 100%;
            max-width: 48em;
            font-size: 4em;
            font-weight: 800;
            @include media('<=800px'){
                font-size: 1.5em;
            }
        }
        
    }

    &__item{
        position: relative;
        width: 100%;
        padding: .3em 0;
        font-size: 3vw;
        display: flex;
        column-gap: 2em;
        align-items: flex-start;
        height: auto;
        @include media('<=1080px'){
            font-size: 2em;
        }
        @include media('<=780px'){
            font-size: 1.4em;
            column-gap: 1.5em;
        }
        span{
            font-size: 1em;
            font-weight: 300;
            display: block;
            z-index: 2;
            mix-blend-mode: difference;
        }
        &:hover{
            .hover-reveal{
                opacity: 1;
            }
            color: inherit;
        }
        strong{
            font-family: $font-body;
            text-transform: uppercase;
            mix-blend-mode: difference;
            font-weight: bold;
            font-style: italic;
        }
        
        &::after{
            @include pseudo-el;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: currentColor;
            mix-blend-mode: difference;
        }
    }
}
.hisia-home-services-list{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.hover-reveal{
	position: absolute;
	z-index: 2;
	width: 4em;
	height: 6em;
	pointer-events: none;
	opacity: 0;
    transition: opacity 0.5s ease-out;
    &__inner{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    &__img{
        @extend %image-center;
    }
}
.hs-home-bg{
    position: relative;
    width: 100%;
    height: 100svh;
    overflow: hidden;
    img{
        @extend %image-center;
        transform: scale(4);
    }
}
.hs-home-studio{
    position: relative;
    width: 100%;
    height: 100svh;
    background: $color;
    color: #ffffff;
    padding: 2svh 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media('<=990px'){
        justify-content: flex-start;
        flex-direction: column;
        padding: 5svh 1em;
        gap: 2em;
    }
    &__image{
        position: relative;
        width: 100%;
        max-width: 48em;
        height: 100%;
        overflow: hidden;
        img{
            @extend %image-center;
            transform: scale(4);
        }
        @include media('<=990px'){
            max-width: 100%;
        }
    }
    &__container{
        position: relative;
        width: 100%;
        @extend %flex-center;
        h1{
            font-size: 3em;
            text-transform: uppercase;
            font-weight: bold;

            @include media('<=990px'){
                font-size: 2em;
                text-align: center;
            }
        }
    }
}