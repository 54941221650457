*, ::after, ::before{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: $font-body;
}
::selection{
    background: $color-secondary;
    color: $background-secondary;
}
h1,h2,h3,h4,strong,p{
    color: inherit;
    font: inherit;
}
html{
    overflow-y: visible;
    &.active{
        overflow-y: hidden;
    }
}
a{
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
span{
    font: inherit;
    color: inherit;
}
button{
    cursor: pointer;
    background: none;
    display: block;
    text-align: left;
    border: none;
    outline: none;
    font: inherit;
}
li{
    list-style: none;
}
body{
    background: $background;
    color: $color;
    font-size: 16px;
    transition: 0.3s ease;
}
.hs-app{
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
}

.hs-wait{
    position: relative;
    width: 100%;
    height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: .4em;
    h1{
        font-weight: bold;
        text-transform: uppercase;
        font-size: 4em;
    }
}