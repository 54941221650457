
.hs-cursor-circle{
    width: 30px;
    height: 30px;
    border: 1px solid white;
    border-radius: 100%;
    position: absolute;
    mix-blend-mode: difference;
    transition: 0.35s ease-out;
    animation: cursorAnim 0.5s infinite alternate;
    pointer-events: none;
    z-index: 999;

    @include media('<=990px'){
        display: none;
    }
    &.expand{
        background: white;
        border: none;
        opacity: 0.2;
        animation: cursorAnim2 0.5s forwards;
    }
    &.hover{
        width: 50px;
        height: 50px;
        animation-play-state: paused;
    }
    &.hoverNavLink{
        background: white;
        animation: cursorAnim3 0.5s infinite alternate;
        
    }
    &.hoverNavLink2{
        width: 50px;
        height: 50px;
        background: white;
        animation: cursorAnim3 0.5s infinite alternate;
        
    }
    &.scrolltobottom{
        background: white;
        // animation: cursorAnim3 0.5s infinite alternate;
        width: 160px;
        height: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: .25px solid white;
        
        &::after{
            content: "Défiler pour voir plus";
            text-align: center;
            font-size: .8em;
            color: #000;
        }
    }
   
}
.hs-cursor-point{
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    background: white;
    border-radius: 100%;
    mix-blend-mode: difference;
    transition: 0.05s ease-out;
    z-index: 999;
    &.hover{
       opacity: 0;
    }

    @include media('<=990px'){
        display: none;
    }
}

@keyframes cursorAnim {
    from{
        transform: scale(1);
    }
    to{
        transform: scale(.8);
    }
}

@keyframes cursorAnim2 {
    0%{
        transform: scale(1);
        
    }
    50%{
        transform: scale(3);
        
    }
    100%{
        transform: scale(1);
        opacity: 0;
    }
}
@keyframes cursorAnim3 {
    0%{
        transform: scale(2);
        
    }
    50%{
        transform: scale(2.8);
        
    }
    100%{
        transform: scale(2);
    }
}