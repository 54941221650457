@font-face {
    font-family: 'Dx Slight Free Personal Use';
    src: url('DxSlightFreePersonalUse-ExBdI.eot');
    src: url('DxSlightFreePersonalUse-ExBdI.eot?#iefix') format('embedded-opentype'),
        url('DxSlightFreePersonalUse-ExBdI.woff2') format('woff2'),
        url('DxSlightFreePersonalUse-ExBdI.woff') format('woff'),
        url('DxSlightFreePersonalUse-ExBdI.ttf') format('truetype'),
        url('DxSlightFreePersonalUse-ExBdI.svg#DxSlightFreePersonalUse-ExBdI') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dx Slight - Free Personal Use';
    src: url('DxSlightFreePersonalUse-MdMed.eot');
    src: url('DxSlightFreePersonalUse-MdMed.eot?#iefix') format('embedded-opentype'),
        url('DxSlightFreePersonalUse-MdMed.woff2') format('woff2'),
        url('DxSlightFreePersonalUse-MdMed.woff') format('woff'),
        url('DxSlightFreePersonalUse-MdMed.ttf') format('truetype'),
        url('DxSlightFreePersonalUse-MdMed.svg#DxSlightFreePersonalUse-MdMed') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

