.hs-loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100svh;
    background: $background;
    z-index: 99;
    @extend %flex-center;
    flex-direction: column;
    row-gap: 1em;
    padding: .8em;
    &.is--disappear{
        display: none;
    }
}
.hs-loader-grid{
    position: relative;
    width: 100%;
    max-width: 28em;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: .5em;
}
.hs-loader-item{
    position: relative;
    width: 100%;
    overflow: hidden;
    transform-origin: bottom;
    .hs-blanc, .hs-noir{
        transform-origin: top
    }
    img{
        @extend %image-center;
    }
    &:nth-child(1){
        height: 7em;
        // animation: animeHeight1 2s ease-in-out infinite alternate-reverse forwards;
    }
    &:nth-child(2){
        height: 9em;
        // animation: animeHeight2 2.3s ease-in-out infinite alternate-reverse forwards;
    }
    &:nth-child(3){
        height: 7em;
        // animation: animeHeight3 1.6s ease-in-out infinite alternate-reverse forwards;
    }
    &:nth-child(4){
        height: 8em;
        // animation: animeHeight4 2.1s ease-in-out infinite alternate-reverse forwards;
    }
}
.hs-blanc{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $background;
    z-index: 2;
}
.hs-noir{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color;
}
.hs-loader-title{
    position: relative;
    h1{
        display: flex;
        font-size: 1.8em;
        font-family: $font-body;
        font-weight: 500;
        overflow-y: hidden;
        @include media('<=990px'){
            font-size: 1em;
        }
        span{
            display: block;
            transform: translateY(1.2em);
        }
    }
}

@keyframes animeHeight1 {
    to{
        transform: scaleY(0.2);
    }
}
@keyframes animeHeight2 {
    to{
        transform: scaleY(.5);
    } 
}
@keyframes animeHeight3 {
    to{
        transform: scaleY(0.1);
    }
}
@keyframes animeHeight4 {
    to{
        transform: scaleY(0.6);
    }
}

.hs-loader-about{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100svh;
    background: $background;
    z-index: 99;
    @extend %flex-center;
    flex-direction: column;
    row-gap: 1em;
    padding: .8em;
    &.is--disappear{
        display: none;
    }
    svg{
        height: 10svh;
        width: auto;
    }
}