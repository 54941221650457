@mixin absolute-el ($top, $right, $bottom, $left, $x, $y){
    position: absolute;
    top: $top;
    bottom: $bottom;
    right: $right;
    left: $left;
    transform: translate($x,$y);
}

@mixin pseudo-el ($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}