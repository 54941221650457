.hs-about{
    position: relative; 
    width: 100%;
}
.hs-about-banner{
    position: relative;
    width: 100%;
    height: 100svh;
    padding: 1em;
    background: $background;
    &__image{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20svw;
        height: 45svh;
        overflow: hidden;
        @extend %flex-center;
        img{
           position: relative;
            object-fit: cover;
            object-position: center;
            width: 140%;
            height: 140%;
        }
        .hs-blanc, .hs-noir{
            transform-origin: top;
        }
        &::after{
            @include pseudo-el;
            top: 0;
            left: 0;
            background: rgba($color: #000000, $alpha: .5);
            width: 100%;
            height: 100%;
        }
    }
    &__container{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 2em);
        height: calc(100% - 2em);
        mix-blend-mode: difference;
        color: #fff;
        // background: red;
        @extend %flex-center;
        flex-direction: column;
        row-gap: 4svh;
        text-align: center;
        padding: 0 2em;
        strong{
            font-size: 1em;
        }
        h1{
            font-size: 4svw;
            font-weight: bold;
            span{
                display: block;
            }
        }
    }
}

.hs-about-introduce{
    position: relative;
    width: 100%;
    min-height: 70vh;
    overflow: hidden;
    @extend %flex-vertical-center;
    padding: 2em;
    background: $background;
    &__container{
        position: relative;
        margin: auto;
        width: 100%;
        max-width: 50em;
        padding: 3em 2em;
        @include media('<=580px'){
            padding: 1.3em;
        }
    }
    
    p{
        font-size: 1.8em;
        width: 100%;
        @include media('<=580px'){
            font-size: 1.4em;
        }
    }
}

.hs-about-bigImage{
    position: relative;
    width: 100%;
    margin: auto;
    height: 100vh;
    margin-bottom: 4em;
    overflow: hidden;
    background: $background;
    &__container{
        position: relative;
        width: 100%;
        height: 100%;
        background: url("../../../../../public/images/normal/imageBg2.jpg");
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;

    }
}


.hs-about-service{
    position: relative;
    width: 100%;
    padding: 3em 1.3em;
    height: 100vh;

    @extend %flex-center;
    background: $background;
    &__container{
        position: relative;
        max-width: 80em;
        width: 100%;
        margin: auto;
    }
    &__head{
        position: relative;
        margin-bottom: 3em;
        h2{
            display: flex;
            column-gap: 1.2em;
            font-size: 1.2em;
            margin-bottom: .8em;
            color: #fff;
            mix-blend-mode: difference;
            span{
                font-weight: 200;
                color: #fff;
                mix-blend-mode: difference;
            }
            strong{
                font-weight: 500;
                text-transform: uppercase;
                color: #fff;
                mix-blend-mode: difference;
            }
        }
        p{
            font-size: 1.3em;
            width: 100%;
            max-width: 58em;
            transition: 0.25s ease-out;
            color: #fff;
            mix-blend-mode: difference;

            @include media('<=580px'){
                font-size: .9em;
            }
        }
    }
    &__list{
        position: relative;
        width: 100%;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        align-items: center;
        // margin-bottom: 80px;
        @include media('<=580px'){
            margin-bottom: 10px;
            row-gap: 2vh;
            column-gap: 0;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
        }
    }
    &__item{
        position: relative;
        width: auto;
        height: auto;
        .hs-about-service__item--link{
            position: relative;
            font-size: 3.4em;
            // font-family: $font-title;
            opacity: 0.6;
            transition: 0.35s ease-out;
            padding: 10px;
            color: #fff;
            mix-blend-mode: difference;
            @include media('<=1080px'){
               font-size: 32px;
               text-align: left;
               padding: 0;
            }
            &:hover{
                opacity: 1;
                color: #fff;
                mix-blend-mode: difference;
            }
        }
        &:hover{
            .hs-about-service__item--img{
                display: block;
            }
        }
        &--img{
            position: absolute;
            width: 240px;
            height: 320px;
            overflow: hidden;
            opacity: 1;
            display: none;
            z-index: 1;
            img{
                @extend %image-center;
            }
        }
    }
}
.hs-line{
    position: relative;
    width: 3.4em;
    height: .1em;
    background: #fff;
    mix-blend-mode: difference;
    @include media('<=980px'){
        width: 40px;
        display: none;
    }
}


.hs-about-expertise{
    position: relative;
    // width: 1480px;
    width: 100%;
    height: 100vh;
    padding: 4.4em 0;
    padding-right: 300px;
    background: rgb(21, 20, 20);
    // margin-top: -5svh;
    @include media('<=1090px'){
        padding: 1.8em;
    }
    &__container{
        position: relative;
        width: 50%;
        margin-left: auto;
        position: relative;
        margin-bottom: 3.4em;
        z-index: 3;
        @include media('<=1090px'){
            position: relative;
            width: 100%;
            margin-left: 0;
            margin-bottom: 1.7em;
            padding: 1.7em;
        }
        h2{
            display: flex;
            column-gap: 1.3em;
            font-size: 1.1em;
            margin-bottom: .9em;
            color: #fff;
            mix-blend-mode: difference;
            span{
                font-weight: 200;
            }
            strong{
                font-weight: 500;
                text-transform: uppercase;
            }
        }
        p{
            font-size: 1.3em;
            width: 100%;
            transition: 0.25s ease-out;
            color: #fff;
            mix-blend-mode: difference;
            @include media('<=1080px'){
                font-size: 14px;
            }
        }
        h1{
            width: 100%;
            font-size: 3em;
            font-weight: 800;
            margin-bottom: 1.5em;
            font-weight: bold;
            transition: 0.25s ease-out;
            font-weight: 400;
            color: #fff;
            mix-blend-mode: difference;
            @include media('<=1080px'){
                font-size: 2em;
            }
        }
    }
    &__img{
        position: absolute;
        width: 45%;
        height: 55vh;
        bottom: 10vh;
        left: 1.5em;
        // transform: translateY(50%);
        z-index: 2;
        overflow: hidden;
        img{
            @extend %image-center;
            
        }
        @include media('<=1090px'){
            position: relative;
            width: 100%;
            margin: auto;
            left: 0;
             bottom: 0;
             height: 50vh
        }
    }
}
.hs-about-emotion{
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 3.5em;
    padding-right: 9em;
    // margin-top: 50vh;
    padding: 3em;
    display: flex;
    align-items: center;
    background: $background;
    @include media('<=1090px'){
       flex-direction: column;
       padding: 1.8em;
       padding-right: 1.8em;
    }
    &__container{
        position: relative;
        width: 50%;
        // margin-top: auto;
        margin-bottom: 3.5em;
        z-index: 3;
        @include media('<=1090px'){
            position: relative;
            width: 100%;
            margin-left: 0;
            margin-bottom: 1.5em;
            padding: 1.5em;
        }
        h2{
            display: flex;
            column-gap: 1.2em;
            font-size: 1.1em;
            margin-bottom: .8em;
            span{
                font-weight: 200;
            }
            strong{
                font-weight: 500;
                text-transform: uppercase;
            }
        }
        p{
            font-size: 1.4em;
            width: 100%;
            transition: 0.25s ease-out;
            @include media('<=1080px'){
                font-size: .9em;
            }
        }
        h1{
            width: 100%;
            font-size: 3em;
            font-weight: 800;
            margin-bottom: .4em;
            font-weight: bold;
            transition: 0.25s ease-out;
            font-weight: 400;
            @include media('<=1080px'){
                font-size: 2em;
            }
        }
    }
    &__img{
        position: absolute;
        width: 45%;
        height: 80vh;
        top: 10%;
        right: 1.8em;
        // transform: translateX(20%);
        z-index: 2;
        overflow: hidden;
        @include media('<=1090px'){
            position: relative;
            width: 100%;
            margin: auto;
            right: 0;
             bottom: 0;
             height: 50vh
        }
        img{
            @extend %image-center;
        }
    }
}
.hs-about-weare{
    position: relative;
    width: 100%;
    height: 100vh;
    @extend %flex-center;
    background: $color;
    color: $background;
    &__container{
        position: relative;
        width: 100%;
        max-width: 48em;
        margin: auto;
        h1{
            font-weight: bold;
            font-size: 8vw;
            text-transform: lowercase;
            text-align: center;
        }
    }
}
.hs-about-quality{
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 3.2em 2.2em;
    background: $color;
    color: $background;
    &__container{
        position: relative;
        width: 100%;
        max-width: 80em;
        margin: auto;
        display: flex;
        justify-content: space-between;

        @include media('<=990px'){
            flex-direction: column;
            justify-content: flex-start;
        }
    }
    &__section{
        position: relative;
        width: calc(50% - 9em);
        display: block;
        &:last-of-type{
            margin-top: 10vh;
        }
        @include media('<=990px'){
            width: 100%;
            &:last-of-type{
                margin-top: 5vh;
            }
        }
    }
}
.hs-about-quality-box{
    position: relative;
    width: 100%;
    margin:  0;
    height: auto;
    padding: 2em 0;
    color: $background;
    @include media('<=990px'){
        margin: 5vh 0;
        padding: .8em 0;
    }
    &__img{
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 70vh;
        img{
            @extend %image-center;
        }
    }
    &__text{
        width: 100%;
        padding: 2.8em 0;
        position: relative;
        h2{
            display: flex;
            column-gap: 1.2em;
            font-size: 1.1em;
            margin-bottom: 10px;
            color: #fff;
            mix-blend-mode: difference;
            @include media('<=990px'){
                font-size: 1.8em;
            }
            span{
                font-weight: 200;
            }
            strong{
                font-weight: 500;
                font-size: 2em;
                text-transform: uppercase;
                @include media('<=990px'){
                    font-size: 1.3em;
                }
            }
        }
        p{
            padding-left: 50px;
            position: relative;
            width: 100%;
            font-size: 1.4em;
            color: #fff;
            mix-blend-mode: difference;
            @include media('<=990px'){
                font-size: 1.2em;
                padding-left: 0px;
            }
        }
    }
}
.hs-about-value{
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    @extend %flex-vertical-center;
    padding: 2em;
    background: $background;
    color: $color;
    &__container{
        position: relative;
        margin: auto;
        width: 100%;
        max-width: 990px;
        padding: 3.2em;
        @include media('<=991px'){
            padding: 10px;
        }
    }
    
    p{
        font-size: 48px;
        width: 100%;
        @include media('<=991px'){
            font-size: 32px;
        }
    }
}